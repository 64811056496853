<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <deal-datatable/>
      </div>
    </div>
  </div>
</template>

<script>

import DealDatatable from "./datatables/DealDatatable";

export default {
  name: "IndexPage",
  components: {DealDatatable}
}
</script>

<style scoped lang="scss">

</style>
